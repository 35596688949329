import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import bscEmpty from '../../assets/images/bsc-empty-token.svg'

const BEP20_DEFAULT_IMG = bscEmpty as string

const BEP20TestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.bsc]: {
    "title": "Binance Coin",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$405.00",
    "address": undefined,
    "link": "https://bscscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.bsc})`,
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.bsc
  },
  CT: {
    "title": "CT Token",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$0",
    "address": "0xF44160104a941e90165804d36a124787A8a6fed7",
    "link": "https://testnet.bscscan.com/token/0xf44160104a941e90165804d36a124787a8a6fed7#balances",
    "group": "Testnet Tokens (BEP 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "CT"
  },
  SLM: {
    "title": "Salmon",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$1.00",
    "address": "0x5be6C80ab7334498f9e89f6b15d4415408f20F65",
    "link": "https://testnet.bscscan.com/address/0x5be6C80ab7334498f9e89f6b15d4415408f20F65",
    "group": "Testnet Tokens (BEP 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SLM"
  },
}

export {BEP20TestnetTokens, BEP20_DEFAULT_IMG}