export async function sendRequestDelay<ReturnType>(requestFn: () => Promise<ReturnType>, delay: number): Promise<ReturnType> {
  return new Promise<ReturnType>((resolve) => {
    setTimeout(async () => {
      try {
        const result = await requestFn();
        resolve(result);
      } catch (error) {
        resolve(error);
      }
    }, delay);
  });
}