import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import baseLogo from '../../assets/images/base-dark.svg'

const BASE_DEFAULT_IMG = baseLogo as string

const BASETestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  tUSDC: {
    "title": "Test USDC",
    "img": "https://sepolia.basescan.org/assets/basesepolia/images/svg/empty-token.svg?v=24.9.2.0",
    "rate": "$1.00",
    "address": "0xB1B74EA6147b5D80048b83AF834FeC2bF862efae",
    "link": "https://sepolia.basescan.org/token/0xB1B74EA6147b5D80048b83AF834FeC2bF862efae",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "tUSDC"
  },
  SLM: {
    "title": "Salmon",
    "img": "https://sepolia.basescan.org/assets/basesepolia/images/svg/empty-token.svg?v=24.9.2.0",
    "rate": "$1.00",
    "address": "0x218D986B4492dC1DdeeEA504Cf178023F726ff32",
    "link": "https://sepolia.basescan.org/address/0x218D986B4492dC1DdeeEA504Cf178023F726ff32",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SLM"
  },
}

export {BASETestnetTokens, BASE_DEFAULT_IMG}