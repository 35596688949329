import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import ethereumLogo from '../../assets/images/ethereum-original-light.svg'

const ERC20_DEFAULT_IMG = ethereumLogo as string

const ERC20TestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://sepolia.etherscan.io/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://ethereum.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  USDT: {
    "title": "Tether USD",
    "img": "https://etherscan.io/token/images/tethernew_32.png",
    "rate": "$1.00",
    "address": "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
    "link": "https://sepolia.etherscan.io/token/0x7169d38820dfd117c3fa1f22a697dba58d90ba06",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "USDT"
  },
  SLM: {
    "title": "Salmon",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x1Bab38919E00B68107A259D48128e779FeF2442A",
    "link": "https://sepolia.etherscan.io/address/0x1Bab38919E00B68107A259D48128e779FeF2442A",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SLM"
  },
  ZYNQ: {
    "title": "Zynq Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xA695a1236f44185aF45Ae0E0D4FFFf634d0F7940",
    "link": "https://sepolia.etherscan.io/address/0xA695a1236f44185aF45Ae0E0D4FFFf634d0F7940",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "ZYNQ"
  },
  KLAIR: {
    "title": "Klair Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xeE79613cb861db3C23D6107314080393FB39230F",
    "link": "https://sepolia.etherscan.io/address/0xeE79613cb861db3C23D6107314080393FB39230F",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "KLAIR"
  },
  DRNX: {
    "title": "Dronix Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x2389e45969A75d590a565bAE3565096971f90Ba7",
    "link": "https://sepolia.etherscan.io/address/0x2389e45969A75d590a565bAE3565096971f90Ba7",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "DRNX"
  },
  VOXU: {
    "title": "Voxura Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xEd8d72134290f3f2C6b990eF32D7f85805eC2dB5",
    "link": "https://sepolia.etherscan.io/address/0xEd8d72134290f3f2C6b990eF32D7f85805eC2dB5",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "VOXU"
  },
  FLEYA: {
    "title": "Fleya Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xE0DDe143A1C34c158EE63bdF6A13e5415ed0dF81",
    "link": "https://sepolia.etherscan.io/address/0xE0DDe143A1C34c158EE63bdF6A13e5415ed0dF81",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "FLEYA"
  },
  QUILZ: {
    "title": "Quilz Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x560cf73b86F4C2444A772E1f506E2Aa68C1F35dE",
    "link": "https://sepolia.etherscan.io/address/0x560cf73b86F4C2444A772E1f506E2Aa68C1F35dE",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "QUILZ"
  },
  PYRUS: {
    "title": "Pyrus Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xC4A78294c1Ba59A744f4CEe1c014c950ed41c34d",
    "link": "https://sepolia.etherscan.io/address/0xC4A78294c1Ba59A744f4CEe1c014c950ed41c34d",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "PYRUS"
  },
  XOLVA: {
    "title": "Xolva Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x8E5172d0826712DB1DF50628b2f7D007bAd12Ca3",
    "link": "https://sepolia.etherscan.io/address/0x8E5172d0826712DB1DF50628b2f7D007bAd12Ca3",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "XOLVA"
  },
  VRANK: {
    "title": "Vrank Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x365Bcb2Da37dfED59AD38b4e25DEA484c661DE41",
    "link": "https://sepolia.etherscan.io/address/0x365Bcb2Da37dfED59AD38b4e25DEA484c661DE41",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "VRANK"
  },
  CELYA: {
    "title": "Celya Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x6418c587f08494572FaE2FB0c112dAEF1a8d80c7",
    "link": "https://sepolia.etherscan.io/address/0x6418c587f08494572FaE2FB0c112dAEF1a8d80c7",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "CELYA"
  },
  MORZA: {
    "title": "Morza Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xa9AF4C4350833aA03AF08A2F4B33079dc55295E2",
    "link": "https://sepolia.etherscan.io/address/0xa9AF4C4350833aA03AF08A2F4B33079dc55295E2",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "MORZA"
  },
  TIRGO: {
    "title": "Tirgo Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x4139DC43198d5eCf12362dDfa05138E4D5615526",
    "link": "https://sepolia.etherscan.io/address/0x4139DC43198d5eCf12362dDfa05138E4D5615526",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "TIRGO"
  },
  DYRIS: {
    "title": "Dyris Coin",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x7A64d8778d36e3E88ed697Abf6Fbc19e97F474A5",
    "link": "https://sepolia.etherscan.io/address/0x7A64d8778d36e3E88ed697Abf6Fbc19e97F474A5",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "DYRIS"
  },
  BOLTA: {
    "title": "Bolta Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xC5644D549D3792A0BeEdeFC44B7c575f4725a54d",
    "link": "https://sepolia.etherscan.io/address/0xC5644D549D3792A0BeEdeFC44B7c575f4725a54d",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "BOLTA"
  },
  SYNTR: {
    "title": "Syntrix Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x374CD98860a586f1543Bb87F817a9ed479d9ADdD",
    "link": "https://sepolia.etherscan.io/address/0x374CD98860a586f1543Bb87F817a9ed479d9ADdD",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SYNTR"
  },
  MEZZY: {
    "title": "Mezzy Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x78c82e50fAeaAf298921c65Ef6FD033BE794dE79",
    "link": "https://sepolia.etherscan.io/address/0x78c82e50fAeaAf298921c65Ef6FD033BE794dE79",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "MEZZY"
  },
  LOVIX: {
    "title": "Lovix Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x86D1C42CE49cBfCd04cceBD7a650D48f977b3DB4",
    "link": "https://sepolia.etherscan.io/address/0x86D1C42CE49cBfCd04cceBD7a650D48f977b3DB4",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "LOVIX"
  },
  VYNE: {
    "title": "Vyne Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xa460Edb9453E1cDdA26183511Cb428DC448F0D47",
    "link": "https://sepolia.etherscan.io/address/0xa460Edb9453E1cDdA26183511Cb428DC448F0D47",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "VYNE"
  },
  FIVUR: {
    "title": "Fivur Token",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x101f05E788BC9D5CDD4786735A6025B357909C76",
    "link": "https://sepolia.etherscan.io/address/0x101f05E788BC9D5CDD4786735A6025B357909C76",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "FIVUR"
  },
}

export {ERC20TestnetTokens, ERC20_DEFAULT_IMG}